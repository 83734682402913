export class BaseOrderItem
{
    #id
    #name
    #sku
    #barCode
    #unit
    #images    

    quantity
    note
    showNote = false

    get id() {
        return this.#id
    }

    get name() {
        return this.#name
    }

    get sku() {
        return this.#sku
    }

    get barCode() {
        return this.#barCode
    }

    get unit() {
        return this.#unit
    }

    get imageURL() {
        return this.#images && this.#images.length > 0 ? this.#images[0].URI : null
    }    

    constructor({ id, name, sku, barCode, unit, quantity, note, images }) {
        // TODO : rework others images in order Item to be like this one
        this.#id = id
        this.#name = name
        this.#sku = sku
        this.#barCode = barCode
        this.#unit = unit
        this.#images = images        

        this.quantity = quantity
        this.note = note
    }

    serialize() {
        return {
            id: this.#id,
            name: this.#name,
            barCode: this.#barCode,
            sku: this.#sku,
            unit: this.#unit,
            images: this.#images,
            quantity: this.quantity,
            note: this.note
        }
    }
}