import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter"
import { BaseOrderItem } from "./BaseOrderItem"

export class RETOGOrder
{
    #orderNumber    
    #creator    
    #status
    #created_at
    #updated_at    

    warehouse
    supplier
    orderItems = []
    note

    get warehouse() {
        return !!this.warehouse ? this.warehouse.name : '-';
    }

    get warehouseId() {
        return !!this.warehouse ? this.warehouse.id : null;
    }

    get warehouseName() {
        return !!this.warehouse ? this.warehouse.name : null;
    }

    get supplier() {
        return !!this.supplier ? this.supplier.name : '-';
    }

    get supplierAddress() {
        return !!this.supplier ? this.supplier.address : '-';
    }

    get supplierTel() {
        return !!this.supplier ? this.supplier.tel : '-';
    }

    get supplierTaxId() {
        return !!this.supplier ? this.supplier.taxid : '-';
    }

    get supplierId() {
        return !!this.supplier ? this.supplier.id : null;
    }

    get orderNumber() {
        return this.#orderNumber
    }

    get creator() {
        return !!this.#creator ? this.#creator.name + ' ' + this.#creator.lastname : '-'
    }    

    get status() {
        return this.#status
    }

    get createdAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#created_at))
    }

    get updatedAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#updated_at))
    }

    constructor({ creator, orderNumber, status, created_at, updated_at, products, note, supplier, warehouse }) {
        this.#orderNumber = orderNumber
        this.#creator = creator
        this.#status = status
        this.#created_at = created_at
        this.#updated_at = updated_at
        this.warehouse = warehouse
        this.supplier = supplier
        
        this.note = note

        if (!!products) {           
            const orderItems = this.orderItems 
            products.forEach(item => {                
                const orderItem = new BaseOrderItem({                    
                    ...item,                    
                    quantity: item.pivot.quantity,
                    note: item.pivot.note
                })                

                orderItems.push(orderItem)
            })            
        }
    }

    addOrderItem(orderItem) {
        if (!(orderItem instanceof BaseOrderItem)) throw "can add only BaseOrderItem Class"
        
        this.orderItems.push(orderItem)
    }

    serialize() {
        return {
            items: this.orderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  note: data.note,                  
                }
            }),                        
            note: this.note,                                    
            warehouse_id: this.warehouseId,
            supplier_id: this.supplierId,
        }
    }
}